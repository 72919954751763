import * as React from "react";
import { graphql } from "gatsby";
import HelpIndex from "../components/helpIndex";

const DiscountMixerHelp = ({ data, location }) => {
  return (
    <HelpIndex
      data={data}
      location={location}
      title="Discount Mixer Help"
      faqUrl="/discount-mixer-faq"
      breadCrumbs={[{ href: "/support-center", label: "Support Home" }, { label: "Discount Support" }]}
    />
  );
};

export default DiscountMixerHelp;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/discount-mixer-help.*index.md/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
